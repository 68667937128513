<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <div class="content-center">
        <div class="row">
          <div class="mx-auto text-center">
            <h2 class="display-2 text-primary">Key Contacts</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="main main-raised">
      <div class="container">
        <Committee />
      </div>
    </div>
  </div>
</template>
<script>
import Committee from "./Committee";;

export default {
  components: {
    Committee
  },
  created () {
    this.$store.dispatch('initCommittee');
  }
};
</script>
<style></style>
