<template>
  <header class="header-global">
    <base-nav
      class="navbar-main headroom"
      id="navbar-main"
      type="success"
      effect="dark"
      expand
    >
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img src="https://glenleatennisclub.com.au/img/brand/white.png" alt="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="javascript:void(0)">
            <img src="https://glenleatennisclub.com.au/img/brand/green.png" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <a class="nav-link text-white" href="/">
            Home
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" href="/coaching">
            Coaching
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" href="/contact-us">
            Contact Us
          </a>
        </li>
      </ul>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <a class="nav-link nav-link-icon" href="https://www.facebook.com/GlenleaTennisClub/" target="_blank">
        <i class="fa fa-facebook-square"></i>
        <span class="nav-link-inner--text d-lg-none">Facebook</span>
      </a>
    </li>
  </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import Headroom from "headroom.js";

export default {
  components: {
    BaseNav,
    CloseButton
  },
  props: {
    navbarType: String
  },
  mounted: function() {
    let headroom = new Headroom(document.getElementById("navbar-main"), {
      offset: 300,
      tolerance: {
        up: 30,
        down: 30
      }
    });
    headroom.init();
  }
};
</script>
<style>
.navbar-main.headroom {
  z-index: 9999;
}
</style>
