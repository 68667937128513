<template>
  <!--     *********    BLACK SIMPLE FOOTER WITH SOCIAL AND BRAND     *********      -->
  <footer class="footer footer-simple bg-default">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
            <img src="https://glenleatennisclub.com.au/img/brand/white-green.png" alt="logo" />
          </router-link>
        </div>
        <div class="col-md-6">
          <div class="column">
            <ul class="inline-menu">
              <li>
                <a class="text-white nav-link" href="/coaching">
                  Coaching
                </a>
              </li>
              <li>
                <a class="text-white nav-link" href="/contact-us">
                  contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3">
          <ul class="social-buttons pull-right">
            <li>
              <base-button
                tag="a"
                size="sm"
                class="text-white btn-facebook mr-2 btn-simple"
                href="https://www.facebook.com/GlenleaTennisClub"
              >
                <i class="fa fa-facebook-square"></i>
              </base-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
