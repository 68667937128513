//import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function sponsors() {

  return axios.get(`${url}/items?filter[category]=Sponsors`)
    .then(response => {
      // console.log(JSON.stringify(response.data))
      return {
        sponsors: jsona.deserialize(response.data)
      };
    });
}

export default {
  sponsors
};
