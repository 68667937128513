<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <div class="content-center">
        <div class="row">
          <div class="mx-auto text-center">
            <h2 class="display-2 text-primary">Glenlea Tennis Club</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="main main-raised">
      <div class="container">
        <div class="row align-items-start">
          <div class="col-lg-8 col-12">
            <p class="lead text-justify mt-0 pb-4">
              Glenlea tennis club, a tennis club located in Adelaide, South Australia and is a member of the Glenelg District Tennis Association (GDTA). It is located on Ferguson Street, Novar Gardens. Competitively, the club field a large number of tennis club teams for both juniors and seniors over the summer and  winter seasons. These playing in the GDTA and Tennis SA competitions. In addition, also run Wednesday Social Night tennis for all standards of players during school terms.

              Glenlea is fortunate to have Todd Langman as our club coach, who is available for private lessons as well as running our junior practices on Wednesday afternoons. Todd is a Tennis Australia Level 2 Club Professional Coach who can cater for players of all abilities.

              The major focus of our tennis club is to create a friendly, social atmosphere for players of all standards and regularly host social functions such as quiz nights, dinners and weekly BBQ’s back at the club. Glenlea also heads up to Melrose (Flinders Ranges) each year for the Easter tournament.

              The club has great club rooms with bar facilities (beer, wine, cider and spirits) and kitchen. EFTPOS is available making it easier to purchase from the bar, pay for competition fees or club merchandise. 
            </p>
          </div>
          <div class="col-lg-4 col-12">
            <iframe style="height: 500px; width: 100%; position: relative; overflow: hidden;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3269.249006278909!2d138.52997221523964!3d-34.97542708036489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0dac449049acf%3A0xdfae8098b7bc77b5!2sGlenlea%20Tennis%20Club!5e0!3m2!1sen!2sau!4v1619979673346!5m2!1sen!2sau" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-12">
            <h3 class="title">Club Mission Statement</h3>
            <div class="text-justify">
              Glenlea Tennis Club is a welcoming and inclusive sporting environment that ensures everyone; participants, coaches, officials, administrators, spectators or any other person involved in, or visiting the club, is made to feel welcome, included, and valued, regardless of age, ability, disability, income, education, sexual orientation, race or religion or personal attributes.
            </div>
          </div>
        </div>
      </div>
    </section>
      <Sponsors />
    </div>
  </div>
</template>
<script>
import Sponsors from "./Sponsors";

export default {
  components: {
    Sponsors
  },
  created () {
    this.$store.dispatch('initSponsors');
  }
};
</script>
<style>
/* If the screen size is 600px or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 900px) {
 .page-header.page-header-small {
        min-height: 34vh  !important;
        max-height: 320px  !important;
    }

h2 {
    font-size: 22px !important;
  }

  p {
    font-size: 12px !important;
  }

}
</style>
