<template>
  <div class="row">
    <div class="col-md-6 col-lg-4" v-for="item in items" :key="item.id">
      <card class="text-white bg-default" data-background="pattern">
        <a href="javascript:;">
          <img class="img pattern rounded" src="https://glenleatennisclub.com.au/img/ill/inn.svg" />
        </a>
        <template slot="body">
          <div class="content">
            <h5 class="h2 card-title text-white mb-2">
              {{ item.name }}
            </h5>
            <h5 class="h5 card-title text-white mb-2">
              {{ getName(item.description) }}
            </h5>
          </div>
        </template>
        <template slot="footer">
          <div class="text-primary text-center">
            <span>{{ getPhone(item.description) }}</span>
            <br />
            <span>{{ getEmail(item.description) }}</span>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    items () {
        console.log(this.$store.getters.committee);
        return this.$store.getters.committee;
    }
  },
  methods: {
    getName(val) {
      let newVal = val.split("|")[1];
      newVal = newVal.replace("name: ", "");
      return newVal;
    },
    getPhone(val) {
      let newVal = val.split("|")[2];
      newVal = newVal.replace("phone:", "");
      return newVal;
    },
    getEmail(val) {
      let newVal = val.split("|")[3];
      newVal = newVal.replace("email:", "");
      return newVal;
    }
  }
};
</script>
