import Vue from "vue";
import App from "./App.vue";
import router from "./starterRouter";
import Argon from "./plugins/argon-kit";
import configPlugin from '@/config'
import store from './store/store';

Vue.config.productionTip = false;
Vue.use(configPlugin)
Vue.use(Argon);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
