import Vue from "vue";
import Router from "vue-router";
import Header from "./layout/AppHeader";
import Footer from "./layout/AppFooter";
import Index from "./views/site/Index.vue";
import ContactUs from "./views/site/ContactUs";
import Coaching from "./views/site/Coaching";
// import Error from "./views/Error.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "index",
      components: {
        header: Header,
        default: Index,
        footer: Footer
      },
      props: {
        header: { navbarType: "success" }
      }
    },
    {
      path: "/contact-us",
      name: "contact-us",
      components: {
        header: Header,
        default: ContactUs,
        footer: Footer
      },
      props: {
        header: { navbarType: "success" }
      }
    },
    {
      path: "/coaching",
      name: "coaching",
      components: {
        header: Header,
        default: Coaching,
        footer: Footer
      },
      props: {
        header: { navbarType: "success" }
      }
    }
    // ,
    // {
    //   path: "*",
    //   name: "error",
    //   components: {
    //     header: Header,
    //     default: Error
    //   },
    //   props: {
    //     header: { navbarType: "default" }
    //   }
    // },
  ]
});
