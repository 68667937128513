import service from "@/store/services/committee-service";

const state = {
  committee: {}
};

const mutations = {
  'SET_COMMITTEE' (state, committee) {
    state.committee = committee;
  }
};

const actions = {
  initCommittee: ({ commit }) => {
    return service.committee().then(({ committee }) => {
      commit('SET_COMMITTEE', committee);
    });
  }
}

const getters = {
  committee: state => {
    return state.committee;
  }
}

const committee = {
  state,
  getters,
  actions,
  mutations
};

export default committee;
