<template>
  <div class="section team-4 bg-success">
    <div class="team-2">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center mb-5">
            <h3 class="display-3 text-white">Sponsors</h3>
          </div>
        </div>
        <div class="row align-items-center">
          <div
            class="col-lg-2 pb-2 mx-auto text-center"
            v-for="item in items"
            :key="item.id"
          >
            <a :href="getUrl(item.description)" target="_blank">
              <img
                :src="item.image"
                alt="Image"
                class="img-fluid rounded shadow-lg"
                style="width: 350px;"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  computed: {
    items () {
        console.log(this.$store.getters.sponsors);
        return this.$store.getters.sponsors;
    },
    committee () {
        console.log(this.$store.getters.committee);
        return this.$store.getters.committee;
    }
  },
  methods: {
    getUrl(val) {
      let newVal = val;
      newVal = newVal.replace("url:", "");
      return newVal;
    }
  }
};
</script>
