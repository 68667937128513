import service from "@/store/services/sponsors-service";

const state = {
  sponsors: {}
};

const mutations = {
  'SET_SPONSORS' (state, sponsors) {
    state.sponsors = sponsors;
  }
};

const actions = {
  initSponsors: ({ commit }) => {
    return service.sponsors().then(({ sponsors }) => {
      commit('SET_SPONSORS', sponsors);
    });
  }
}

const getters = {
  sponsors: state => {
    return state.sponsors;
  }
}

const sponsors = {
  state,
  getters,
  actions,
  mutations
};

export default sponsors;
