import Vue from 'vue'
import Vuex from 'vuex'

import sponsors from "./modules/sponsors-module";
import committee from "./modules/committee-module";


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    sponsors,
    committee
  }
});
